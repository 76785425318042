export const environment = {
	production: true,
	dev: true,
	version: '0',
	pdt: {
		pais: { code: 'mx', name: 'México' },
		region: { region: 'Ciudad de Mexico', country: 'mx' },
		fon: { idFon: 50, codigoForPag: 1, idMdl: 0, codigo: '01', fon: 'Efectivo', idMon: 'MXN', baja: 0 },
		regFis: { codigo: '601', regFis: "General de Ley Personas Morales" }
	},
	socketsURL: 'https://dev.oadmin.app/',
	apiURL: 'https://dev.oadmin.app/',
	mercadolibreRedirectUrl: 'https://dev.oadmin.app/',
	mercadolibreApiKey: '4944760888652695', // public - dev apikey
	stripeKey: 'pk_test_wf55CdE9npp3EdL44h7xr1mD00gYNKZnux',//testing
	paypalToken: 'A21AAGO2hXskMjlC14RAKzWLWE-ocrJcmVCAM6AgZ1v4jNoOZdmz80OMHh4CgvzPjjxI9YzobsojYd5QVSeaOa7rskXb73_Fg',
	vapidPublicKey: "BMqda28A7qr68on2XilLNUilkGOLu4Ks94kL-OlZF-O2rX1EghlzuHNly1C12QqVpz3UypEM7n4Cp9jMB_8VRLQ",
	amazonAppId: 'amzn1.sellerapps.app.708b8005-83ee-46c6-aa50-69f782023722', // public
	shopifyApiKey: '9e46ee4609cf70b557d267fc08296fc5',
	datosTesting: {
		email: '',
		nombre: '',
		apellido: '',
		tel: '',
		pass: '',
		fcp: {
			nom: '',
			ape: '',
			fcp: '',
			email: '',
			tel: '',
			envDom: '',
			envDomNro: '',
			envDomInt: '',
			envDomCodPos: '',
			envDomCol: '',
			envDomCiu: 'Ciudad de Mexico',
			envDomMun: '',
			envDomRegion: { country: 'mx', region: 'Ciudad de Mexico' },
			envDomPais: { code: 'mx', name: 'Mexico' },
			usu: {
				usu: '',
				pass: ''
			}

		}
	}
};
